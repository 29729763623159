.callList .modal-input-container p
{
    margin: 0 ;
}
.callList-header th
{
    font-weight: bold;
    width: 25%;
}
.callList .modal-body .row
{
    border: 1px solid #ccc;
    margin: 10px;
    border-radius: 10px;
}