.main {
    background-color: var(--bg-color-2);

}


.div_button {
    display: flex;
    justify-content: end;
    margin: 20px;

}

.detail-button {
    color: var(--font-color-2);
    display: flex;
    gap: 5px;

    @media only screen and (max-width: 600px) {
        width: 50px;
        align-items: center;

    }
}

.text-btn {
    @media only screen and (max-width: 600px) {
        display: none;

    }
}


.content {
    padding-top: 10px;
    /* width: 80%; */
    height: 100vh;
    /* padding-bottom: 50px; */
    background-color: var(--bg-color-1);
    /* margin: auto; */
    /* margin-top: 20px; */
    margin: 30px 30px 0 30px;
    border: 1px solid var(--shadow-color);
    border-radius: 10px;
    /* margin-bottom: 50px; */
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    @media only screen and (max-width: 600px) {
        /* margin-top: 100px; */
        /* width: 95%; */
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
}

.user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @media only screen and (max-width: 600px) {
        width: 40px;
        height: 40px;
    }
}

.Background-user {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 5px;
    margin-left: 5px;

    @media only screen and (max-width: 600px) {
        width: 40px;
        height: 40px;
    }
}

/* user */
.main_users {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.users-text {
    border: 2px solid var(--shadow-color);
    border-radius: 10px;
    height: auto;
    width: 90%;
    text-align: justify;
    padding: 10px;
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        display: flex;
    }
}

/* admin */
.main_admmin {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

}

.admin-text {
    border: 2px solid var(--shadow-color);
    border-radius: 10px;
    height: auto;
    width: 90%;
    text-align: justify;
    background-color: var(--color-1);
    color: white;
    padding: 10px;
    margin-top: 0px;

    @media only screen and (max-width: 600px) {
        width: 90%;
        display: flex;
    }
}

.admin_image {
    border-image: 20px;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 10px;
    padding-top: 10px;

    @media only screen and (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
}



.background_admin {
    width: 50px;
    height: 50px;
    background-color: var(--color-1);
    border-radius: 100%;


    @media only screen and (max-width: 600px) {
        width: 40px;
        height: 40px;
    }
}

/* form */
.form-style {
    /* background-color: red; */
    display: flex;
    gap: 10px;
    justify-content: center;
    /* margin-top: 50px; */
    padding-right: 0px;
    padding-left: 30px;
    width: 80%;
    position: fixed;
    bottom: 5px;

    @media only screen and (max-width: 600px) {
        width: 90%;
    }

    @media only screen and (max-width: 768px) {
        width: 90%;
    }
}

.form-style input {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: var(--shadow-color);
    border-radius: 10px;
    outline: none;
}

.form-style button {
    background-color: var(--shadow-color);
    border: none;
    outline-color: aquamarine;
    border-radius: 100px;
    padding: 10px;
    color: var(--color-1);
    align-items: center;
}