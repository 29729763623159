.header {
    /* background-color: var(--bg-color-1); */
    background-color: transparent;
    border-bottom: 2px solid var(--bg-color-2);
    position: sticky;
    top: 0;
    padding: 7px 15px;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.icon-badge-container {
    position: relative;
    margin-right: 5px;
}

.badge {
    position: absolute;
    background-color: var(--danger-color);
    padding: 0;
    width: 13px;
    height: 13px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: normal;
    top: -1px;
    right: 2px;
}

.header-icon {
    margin-right: 10px;
    font-size: 18px;
    color: var(--font-color-5);
    cursor: pointer;
    transition: all .3s;
}

.header-icon:hover {
    color: var(--font-color-1);
}

.header-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
}

/* Exit Modal */
.exit-modal-btn {
    border: none;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 5px;
    transition: all .2s;
}

.exit-modal-btn:hover {
    filter: brightness(95%);
}

.exit-modal-btn.exit-btn {
    background-color: var(--danger-color);
    color: var(--font-color-3);
}