.modal-role-container {
    font-size: 14px;
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
}

.modal-role-container .role{
    background-color: var(--shadow-color);
    padding: 5px 15px;
    margin-left: 10px;
    border-radius: 5px;
}

.modal-role-container .role:hover{
    cursor: pointer;
}

.modal-role-container .role.active{
    background-color: var(--color-1);
    color: var(--font-color-3);
}

.hover-close-model :hover {
    cursor: pointer;
}