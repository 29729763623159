/* Light Mode */
:root {
    --color-1: #009688;
    --color-2: #f6c000;
    --color-3: #f8285a;
    --color-4: #17c653;
    --color-5: #7239ea;

    --border-color-1: #EAECF0;
  
    --danger-color: #D30D0D;
    --success-color: #17c653;
  
    --font-color-1: #333;
    /*مشکی تر*/
    --font-color-2: #888;
    /*خاکستری روشن*/
    --font-color-3: #fff;
    /*سفید*/
    --font-color-4: #555;
    --font-color-5: #777;
  
    --bg-color-1: #fff;
    /*سفید*/
    --bg-color-2: #f7f7f7;
    /*توسی خیلی روشن */
  
    --shadow-color: #eaeaea;
    --shadow-color-2: #f0eeee;
  }

