.field-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}


.input-group {
    display: flex;
    border: 2px solid var(--color-1);
    color: var(--font-color-1);
    border-radius: 4px;
    margin-top: 4px;
    padding: 4px;
    padding-right: 8px;
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input-group input {
    border: none;
    outline: none;
}


.dropdown {
    position: absolute;
    display: flex;
    justify-content:  center;
    margin-top: 4px;
    overflow: auto;
}

.dropdown ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
    padding-right: 16px;
    width: 205px;
    border-radius: 4px;
    background-color: var(--color-1);
    z-index: 10;
    padding-top: 8px;
    overflow: auto;
}

.dropdown ul li {
    list-style: none;
    text-align: right;

}

.dropdown ul li:hover {
    list-style: none;
    text-align: right;
    color: var(--color-2);
    cursor: pointer;
}



