.info-list-navigate-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--shadow-color);
    margin: 40px 0 30px;
    padding: 5px 15px;
    border-radius: 10px;
}

/* Right Side */
.info-list-navigate-section .right-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-list-navigate-link {
    margin-left: 10px;
    font-size: 13px;
    color: var(--font-color-1);
    transition: all .1s;
    padding: 5px 15px;
    border-radius: 5px;
}

.info-list-navigate-link .icon {
    margin-left: 5px;
}

.info-list-navigate-link:hover,
.info-list-navigate-link.active {
    color: var(--font-color-3);
    background-color: var(--color-1);
    /* border-bottom: 2px solid var(--color-1); */
}

/* Response navigate right side */
.info-list-navigate-section .right-side{
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;
}

.info-list-navigate-response-container {
    position: relative;
    display: none;
}

.info-list-navigate-response-container .icon {
    color: var(--color-1);
    font-size: 18px;
    cursor: pointer;
}

.info-list-navigate-response {
    background-color: var(--bg-color-1);
    border: 2px solid var(--bg-color-2);
    font-size: 14px;
    position: absolute;
    width: max-content;
    border-radius: 5px;
    padding: 10px 10px 10px 50px;
    z-index: 5;
}

.info-list-navigate-response.hidden {
    display: none !important;
}

.info-list-navigate-response.show {
    display: inline-block;
}

.info-list-navigate-link-response{
    list-style: none;
    padding-bottom: 10px;
}

.info-list-navigate-link-response:hover,
.info-list-navigate-link-response.active{
    color: var(--color-1);
}


/* Left Side */
.info-list-navigate-section .left-side {
    text-align: left;
}

.add-new-info-btn {
    border: none;
    color: var(--font-color-1);
    background-color: transparent;
    font-size: 14px;
    border-radius: 5px;
    padding: 7px 12px;
    transition: all .2s;
}

.add-new-info-btn:hover {
    background-color: var(--bg-color-2);
}

.add-new-info-btn .icon {
    color: var(--color-1);
    font-size: 18px;
    margin-left: 5px;
}

.add-new-info-btn .iconForSearch {
    color: var(--color-1);
    font-size: 23px;
    margin-left: 5px;
}


/* Media Query */
@media screen and (max-width: 550px) {

    .add-new-info-btn {
        padding: 0;
        background-color: transparent;
    }

    .add-new-info-btn .btn-text {
        display: none;
    }

    .add-new-info-btn .icon {
        font-size: 25px;
        color: var(--color-1);
    }

    .add-new-info-btn .iconForSearch {
        color: var(--color-1);
        font-size: 26px;
        margin-left: 5px;
    }
    .info-list-navigate-section .left-side {
        gap: 0  !important;
    }
}

@media screen and (max-width: 450px){
    .info-list-navigate-tag-link {
        display: none;
    }

    .info-list-navigate-response-container{
        display: block;
    }
}