/* ----- Main Sidebar ----- */
.sidebar {
    background-color: var(--color-1);
    height: 100vh;
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 20px 5px var(--shadow-color);
    z-index: 20;
    overflow-y: auto;
    scrollbar-width: none;
    user-select: none;
}

.sidebar.flex {
    flex: 1;
}

/* Sidebar Header */
.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px;
    padding-left: 0;
}

.sidebar-title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color-2);
    width: 100%;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
}

.sidebar-title {
    font-size: 16px;
    padding-top: 10px;
    color:var(--font-color-1);
}

.sidebar-title.hidden {
    display: none;
}

.logo {
    margin-left: 7px;
    font-size: 27px;
    color: var(--color-1);
}

.sidebar-toggle-btn {
    position: absolute;
    bottom: -12px;
    left: 0;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    color: var(--color-1);
}

.sidebar-toggle-btn:hover {
    opacity: 70%;
}

.sidebar-item-title.hidden {
    display: none;
}

/* Sidebar Body */
.sidebar-list {
    margin-top: 30px;
    list-style: none;
    overflow-y: auto;
    scrollbar-width: none;
    padding-left: 0;
}

.sidebar-item {
    display: flex;
    padding: 9px 15px;
    font-size: 15px;
    margin-bottom: 8px;
    cursor: pointer;
}

.sidebar-item-title {
    color: var(--font-color-3);
}

.sidebar-item:hover .sidebar-item-title,
.sidebar-item.active .sidebar-item-title,
.sidebar-item:has(.dropdown-item.active) .sidebar-item-title {
    color: var(--font-color-1);
}

.sidebar-item:hover,
.sidebar-item.active,
.sidebar-item:has(.dropdown-item.active) {
    /* border-right: 4px solid var(--color-1); */
    background-color: var(--bg-color-2);
    transition: all .2s;
}

.sidebar-item-icon {
    font-size: 17px;
    margin-left: 10px;
    color: var(--font-color-3);
}

.sidebar-item:hover .sidebar-item-icon,
.sidebar-item.active .sidebar-item-icon,
.sidebar-item:has(.dropdown-item.active) .sidebar-item-icon,
.sidebar-item:hover .dropdown-item-toggle,
.sidebar-item.active .dropdown-item-toggle,
.sidebar-item:has(.dropdown-item.active) .dropdown-item-toggle {
    color: var(--color-1);
}

/* ----- Sidebar in small size ----- */
.sidebar-menu-icon {
    position: absolute;
    top: 40px;
    z-index: 1;
    background-color: var(--bg-color-1);
    box-shadow: 0 0 5px 2px var(--shadow-color);
    color: var(--color-1);
    padding: 5px 10px;
    border-radius: 5px 0 0 5px;
    font-size: 20px;
    cursor: pointer;
    transition: all .1s;
}

.sidebar-menu-icon:hover {
    font-size: 22px;
    padding: 4px 14px;
}

.sidebar-sm-size {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 20;
    overflow-y: auto;
    scrollbar-width: none;
    background-color: var(--color-1);
}

.sidebar-sm-size.hidden {
    display: none;
}

.sidebar-sm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: var(--font-color-1);
    margin-bottom: 25px;
    border: 1px solid var(--font-color-3);
    /* box-shadow: 0 0 5px 2px var(--shadow-color); */
}

.sidebar-sm-title {
    font-size: 18px;
    color: var(--font-color-3);
}

.sidebar-sm-close-btn {
    cursor: pointer;
    transition: all .1s;
    color: var(--font-color-3);
}

.sidebar-sm-close-btn:hover {
    color: var(--danger-color);
}

.sidebar-sm-list {
    margin: 0;
    padding: 0;
    padding-left: 5px;
}


/* Media Query */
@media screen and (min-width: 1200px) {

    .sidebar-sm-size {
        display: none;
    }

}

@media screen and (max-width: 1200px) {

    .sidebar {
        display: none;
    }

}