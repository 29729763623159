.info-list-title-section {
    margin-bottom: 40px;
}

.info-list-title {
    font-size: 23px;
    color: var(--font-color-1);
}

.info-list-subtitle {
    font-size: 14px;
    color: var(--font-color-2);
}