/* Search Box Styles */
.search-box-container {
    border: 2px solid var(--bg-color-2);
    padding: 5px 10px;
    width: fit-content;
    display: flex;
    border-radius: 5px;
}

.search-box-container input {
    border: none;
    outline: none;
    font-size: 14px;
    width: 200px;
}

.search-box-container button {
    border: none;
    outline: none;
    background-color: var(--color-1);
    color: var(--font-color-3);
    font-size: 13px;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 50%;
    transition: all .2s;
}

.search-box-container button:hover {
    filter: brightness(108%);
    font-size: 15px;
}

/* Modal Styles */
.modal-content {
    overflow: hidden;
}

.modal-header {
    padding: 12px 20px;
}

.modal-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
}

.modal-title {
    font-size: 15px;
}

.hover:hover{
    cursor: pointer;
}

.modal-body {
    text-align: center;
    padding: 20px;
    font-size: 15px;
    margin: 5px 0;
}

/* modal inputs */
.modal-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.modal-input-container label {
    font-size: 13px;
    margin-bottom: 5px;
    color: var(--font-color-1);
}

.modal-input-container input,
.modal-input-container textarea {
    background-color: var(--shadow-color);
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px 10px;
}

.modal-input-container textarea {
    overflow-y: auto;
}

.modal-dropdown-container {
    position: relative;
}

.modal-dropdown-input-container {
    position: relative;
    border-radius: 5px;
}

.modal-dropdown-input-container input {
    width: 100%;
    border-radius: 5px;
}

.modal-dropdown-input-container .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 13px;
    cursor: pointer;
}

.modal-dropdown-list {
    background-color: var(--bg-color-2);
    list-style: none;
    padding: 0px 0;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top: 60px;
    width: 100%;
    max-height: 150px;
    overflow: auto;
    z-index: 100;
    scroll-snap-type: y proximity;
}

.modal-dropdown-list li {
    cursor: pointer;
    padding: 3px 10px;
    transition: all .1s;
    font-size: 14px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.modal-dropdown-list li:hover {
    background-color: var(--color-1);
    color: var(--font-color-3);
}

/* dropdown search input */
.search-dropdown-input{
    border: 1px solid var(--shadow-color-2);
    background-color: var(--bg-color-1);
    outline: none;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
}

/* modal btns */
.modal-btn {
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: transparent;
    font-size: 14px;
    transition: all .2s;
}

.modal-btn:hover {
    filter: brightness(90%);
}

.modal-btn .icon {
    margin-left: 5px;
    font-size: 12px;
}

.modal-green-btn {
    background-color: var(--color-1);
    color: var(--font-color-3);
}

.modal-red-btn {
    background-color: var(--danger-color);
    color: var(--font-color-3);
}

.modal-cancle-btn {
    background-color: var(--shadow-color);
}



/* Add Modal Styles */
.add-city-modal-desc {
    margin: 5px 0 25px;
    font-size: 14px;
    color: var(--font-color-1);
}

/* File Input */
.img-picker-container input {
    width: 100%;
}

/* Pagination Sytles */
.pagination-section {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.pagination-btn {
    border-radius: 5px;
    border: 1px solid var(--font-color-2);
    padding: 3px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s;
}

.pagination-btn:hover {
    background-color: var(--shadow-color);
}

/* Info Table */

.info-list-table-section {
    margin-top: 10px;
}

.info-list-table {
    width: 100%;
    border-collapse: separate;
}

.info-list-tr-head th {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-color-1);
    padding: 15px 20px;
    border-bottom: 2px solid var(--border-color-1);
    border-top: 2px solid var(--border-color-1);
}

.info-list-tr-head th:last-child {
    border-radius: 20px 0 0 0 !important;
    border-left: 2px solid var(--border-color-1);
}

.info-list-tr-head th:first-child {
    border-radius: 0 20px 0 0 !important;
    border-right: 2px solid var(--border-color-1);
}

.info-list-table th,
.info-list-table td {
    text-align: right;
}

.info-list-row {
    border-radius: 20px;
}

.info-list-row td {
    padding: 15px 20px;
    border-bottom: 2px solid var(--border-color-1);
    color: var(--font-color-4);
}

.info-list-row td:last-child {
    border-left: 2px solid var(--border-color-1);
}

.info-list-row td:first-child {
    border-right: 2px solid var(--border-color-1);
}

.info-list-row:last-child td:last-child {
    border-radius: 0 0 0 20px;
}

.info-list-row:last-child td:first-child {
    border-radius: 0 0 20px 0;
}

.info-list-row:hover td {
    color: var(--font-color-1);
}

.table-profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.info-list-btns-container {
    border: 2px solid var(--bg-color-2);
    border-radius: 8px;
    width: fit-content;
    margin: auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.info-list-btn {
    background-color: transparent;
    width: 100%;
    border: none;
    outline: none;
    padding: 3px 8px;
    transition: all .2s;
}

.info-list-btn.edit-btn {
    color: var(--color-2);
}

.info-list-btn.edit-btn:hover {
    color: var(--font-color-3);
    background-color: var(--color-2);
    opacity: 80%;
}

.info-list-btn.delete-btn {
    color: var(--color-3);
}

.info-list-btn.delete-btn:hover {
    color: var(--font-color-3);
    background-color: var(--color-3);
    opacity: 90%;
}

/* Tags */
.active-tag{
    border: 1px solid var(--color-1);
    font-size: 14px;
    color: var(--color-1);
    padding: 0 5px;
    border-radius: 3px;
}

.disabled-tag{
    border: 1px solid var(--danger-color);
    font-size: 14px;
    color: var(--danger-color);
    padding: 0 5px;
    border-radius: 3px;
}

.pending-tag{
    border: 1px solid var(--font-color-2);
    font-size: 14px;
    color: var(--font-color-2);
    padding: 0 5px;
    border-radius: 3px;
}

/* Media Query */
@media screen and (max-width: 750px) {
    .td-res-1 {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .td-res-2 {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .info-list-table-section {
        margin-top: 20px;
    }
}

@media screen and (max-width: 450px) {
    .td-res-3 {
        display: none;
    }

    .first-col {
        text-align: right !important;
    }

    td.first-col {
        padding-right: 10px !important;
    }
}