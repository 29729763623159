.Violation{
    padding: 9px 15px;
    color: white;
    display: flex;
    row-gap: 17px;
    height: 40px;
    cursor: pointer;
}
.Violation:hover{
    background-color: white;
    color: black;
}
.Violation:hover .sidebar-item-icon{color: #009688;}
.violation-citiesNames , .illegal-crate , .violation-show-delete{
    display: flex;
    gap: 0px  5px;
}
.users-and-numbers img
{
    width: 45px;
}
.violation-showDetail{color: #009688;border: none;background-color:transparent;}
.violation-deleteDetail{color: #D30D0D;border: none;background-color: transparent;}
.illegal-crate button{
    border: 2px solid #D30D0D;
    height: 20px;
    display: flex;
    color: rgb(59, 59, 59);
    justify-content: center;
    align-items: center;
    border-radius:2px;
    width: 20px;
}
.violator-details-profile{
    display: grid;
    place-items: center;
    margin-top: 12px;
}
.violator-details-panel{
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 14px 0;
}
.violation-field p , .violator-details-profile p{margin: 0;}
.violation-field{
    background-color: #EEEEEE;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
}
.violation-blockBtn{
    background-color:#D30D0D;
    border: none;
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    color: white;
    padding:8px 0;
    transition: all ease 0.2s;
}
.violation-blockBtn:hover
{
    filter: brightness(90%);
}
#platform_dropdown{
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
}
.violation-info-list p
{
    margin : 0;
}