/* Breadcrumbs */
.breadcrumbs-container {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
}

.breadcrumbs-container .icon {
    font-size: 13px;
    margin: 0 5px;
}

.breadcrumbs-container .link:hover {
    color: var(--color-1);
}