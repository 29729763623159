.platform-btns-container {
    margin-top: 35px;
    margin-bottom: -15px;
}

.platform-btns-container button {
    border: none;
    outline: none;
    background-color: var(--shadow-color);
    font-size: 14px;
    margin-left: 10px;
    padding: 4px 15px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .platform-btns-container button {
        margin-top: 12px;
    }
}

.platform-btns-container button.active {
    background-color: var(--color-1);
    color: var(--font-color-3);
}

#connectionsPannel-ui {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px 10px;
    text-align: right;
    gap: 15px;
}

#connectionsItems-design p {
    padding: 0;
    margin: 5px 0px;
}

#connectionsItems-design {
    background-color: rgb(218, 218, 218);
    border-radius: 10px;
    padding: 10px;
}

.groupConnection-design p {
    padding: 0px;
    margin: 0px;
}

.groupConnection-design {
    display: flex;
    flex-direction: column;
    border: 1px rgb(126, 126, 126) solid;
    margin-top: 15px;
    border-radius: 15px;
}

.groupItems-container {
    padding: 20px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    gap: 30px;
}

.pic-holder {
    border-radius: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 80px;
    position: absolute;
    right: 50px;
    bottom: -40px;
    border: 2px solid rgb(28, 107, 87);
    height: 80px;
    background-color: white;
}

.group-list-container {
    background-color: var(--bg-color-2);
    border-radius: 5px;
    overflow: auto;
    height: 250px;
}

.group-list-item {
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid var(--shadow-color);
    font-size: 14px;
}

.group-list-item:hover {
    background-color: var(--shadow-color);
}

.group-list-item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 6px;
    border: 1px solid var(--shadow-color);
}

.tag-container {
    display: flex;
}

.tag-container .tag {
    background-color: var(--shadow-color);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 5px 9px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.tag-container .tag .icon {
    margin-right: 10px;
    font-size: 12px;
    color: var(--danger-color);
    cursor: pointer;
}