@font-face {
  font-family: "vazir";
  src: url('../public/fonts/Vazir-Regular.ttf') format('truetype'),
    url('../public/fonts/Vazir-Regular.woff') format('woff');
}

/* Light Mode */
/* :root {
  --color-1: #43ced7;
  --color-2: #f6c000;
  --color-3: #f8285a;
  --color-4: #17c653;
  --color-5: #7239ea;

  --danger-color: #ec0f42;
  --success-color: #17c653;

  --font-color-1: #333; */
  /*مشکی تر*/
  /* --font-color-2: #888; */
  /*خاکستری روشن*/
  /* --font-color-3: #fff; */
  /*سفید*/
  /* --font-color-4: #555; */
  /* --font-color-5: #777; */

  /* --bg-color-1: #fff; */
  /*سفید*/
  /* --bg-color-2: #f7f7f7; */
  /*توسی خیلی روشن */

  /* --shadow-color: #eaeaea; */
  /* --shadow-color-2: #f0eeee; */
/* } */

/* Dark Mode */
/* :root {  */
  /* --color-1: #385bcf;
  --color-2: #edba11 ;
  --color-3: #f06d25;
  --color-4: #00a72e;
  --color-5: #f8285a;

  --danger-color: #ec0f42;
  --success-color: #17c653;

  --font-color-1: #fff;  */
  /* سفید */
  /* --font-color-2: #f1f1f1;  */
  /* خاکستری خیلی روشن */
  /* --font-color-3: #333;  */
  /* ؟؟؟سفید */

  /* --bg-color-1: #23262d;  */
  /* خاکستری تیره */
  /* --bg-color-2: #31343d;  */
  /* خاکستری روشن */
/* } */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "vazir", vazir, sans-serif;
}

.link {
  color: var(--font-color-1);
  text-decoration: none;
}

body {
  color: var(--font-color-1);
}

.show-element {
  display: block;
}

.hide-element {
  display: none;
}


/* Scrollbar Styles */
::-webkit-scrollbar {
  width: .4vw;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-color-1);
  border-radius: 10em;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: var(--font-color-2);
  border-radius: 10em;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-1);
}


/* Selection Style */
::selection {
  background-color: var(--color-1);
  color: var(--font-color-3);
}