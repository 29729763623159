.basic-info-card {
    background-color: var(--bg-color-2);
    border-radius: 5px;
    padding: 10px;
    border: 2px solid var(--shadow-color);
    margin-bottom: 20px;
}

.basic-info-card .city-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.basic-info-card .city-infos .state-name {
    font-size: 12px;
    color: var(--color-1);
    margin-top: 8px;
}

.basic-info-card .city-infos .btns-container {
    display: flex;
    align-items: center;
    color: var(--font-color-3);
}

.basic-info-card .city-infos .btns-container .edit-btn-container,
.basic-info-card .city-infos .btns-container .delete-btn-container {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 13px;
    cursor: pointer;
}
.basic-info-card .city-infos .btns-container .edit-btn-container:hover,
.basic-info-card .city-infos .btns-container .delete-btn-container:hover{
    filter: brightness(90%);
}

.basic-info-card .city-infos .btns-container .edit-btn-container {
    background-color: var(--color-1);
    margin-left: 5px;
}

.basic-info-card .city-infos .btns-container .delete-btn-container {
    background-color: var(--danger-color);
}