.info-list-row .img-container
{
    display: flex;
    align-items: center;
}
.info-list-row .img-container img
{
    border: 1px solid #F3F3F3;
    /* padding: 10px; */
    border-radius: 10px;
    margin-left: 5px;
    height: 50px;
    width: 50px;
}
.info-list-row p
{
    margin: 0;
}
.info-list .td-res-1
{
    height: 80px;
    padding: 0;
}
.modal .modal-members-container
{
    width:100%;
    border-radius: 5px;

}
.modal .modal-members-container ul
{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

.filter-side {
    margin: 24px;
    display: flex;
    justify-content: space-between;
}

.filter-side-element {
    display: flex;
    gap: 36px
}

.button-filter {
    display: flex;
    align-items: end;
    margin-bottom: 14px;
    gap: 12px;
}




@media screen and (max-width: 768px) {
    .filter-side {
        flex-direction: column
    }

    .filter-side-element {
        flex-direction: column;
        gap: 0;
    }

    .button-filter {
        justify-content: space-between;
    }

    .button-filter button {
        width: 50%;
    }
}

