.modal-dropdown-input-container
{
    display: flex;
    align-items: center;
    background-color: #eaeaea;
}
.modal-dropdown-input-container img
{
    width: 16px;
    margin-left: 10px;
    cursor: pointer;
}
textarea
{
    border-radius: 7px;
    resize: none;
    height: 160px;
    background-color: rgb(234, 234, 234);
    outline: none;
    border: none;
    padding: 5px 10px;
}
.modal #newsDesc
{
   align-items: center;
   display: flex;
}
.modal #newsDesc img
{
    width: 30px;
    margin-left: 5px;
}
::-webkit-scrollbar-track {
    background-color: #EAEAEA;
}
.scroll-container
{
    height: 180px;
    overflow-y: auto;
}
.descnewsInfo
{
    margin-top: 30px;
}
.descnewsInfo p
{
    font-weight: bold;
}
.news .modal-dialog
{
    
    max-width: 560px ;
   
}
.slick-slide img
{
    height: 110px !important;
    border-radius: 10px;
}
.slick-initialized .slick-slide {
    margin-left: 10px;
    height: 100%;
}
.slick-slider ,.slick-list 
{
    height: 100%;
}
.slick-track
{
    height: 100%;
}
.modal-img .modal-content 
{
    width: 99%;
}
@media screen and (max-width: 475px){
    .modal .fullImg
    {
        height: 300px !important;
    }    
}