.statistics-card {
    background-color: var(--color-1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin-top: 20px;
}
.statistics-card:hover
{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.statistics-card .icon{
    font-size: 70px;
    margin-left: 15px;
    color: var(--font-color-3);
    opacity: .6;
}

.statistics-card .title{
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 10px;
    color: var(--font-color-3);
}

.statistics-card .statstic{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--font-color-3);
}
.statistics-card .statstic .small
{
    margin:  0 !important;
}