.login {
    display: flex;
    height: 100vh;
    padding: 20px 0;
    justify-content: space-between;
}


/* Right Side Styles */
.login .right-side {
    width: 45%;
    background-color: var(--bg-color-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-container {
    width: 70%;
}

.login-title {
    font-size: 22px;
    color: var(--font-color-4);
    font-weight: bold;
}

.login-title span {
    font-size: 28px;
    font-weight: bold;
    margin-right: 5px;
    color: var(--color-1);
}

.login-subtitle {
    font-size: 15px;
    color: var(--font-color-4);
    margin-top: 8px;
}

.login-form-container form {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.login-form-container label {
    font-size: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.login-form-container label .icon {
    color: var(--color-1);
    margin-left: 5px;
    font-size: 14px;
}

.login-form-container input {
    border: none;
    outline: none;
    background-color: var(--bg-color-2);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: var(--font-color-1);
    border: 2px solid var(--bg-color-2);
    transition: all .2;
}

.login-form-container input:focus {
    border: 1px solid var(--color-1);
}

.button-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.button-link-container button {
    outline: none;
    border: none;
    background-color: var(--color-1);
    color: var(--font-color-3);
    border-radius: 5px;
    padding: 8px 0;
    width: 35%;
    transition: all .2;
    width: 100%;
}

.button-link-container button:hover {
    filter: brightness(110%);
}

.login-support-link {
    font-size: 15px;
    color: var(--font-color-4);
    cursor: pointer;
    transition: all .2s;
}

.login-support-link .icon {
    margin-left: 5px;
}

.login-support-link:hover {
    color: var(--color-1);
    font-weight: bold;
}


/* Left Side Styles */
.login .left-side {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-image {
    width: 60%;
}

/* Media Query */
@media screen and (max-width: 1040px) {
    .login .right-side {
        width: 60%;
    }

    .login .left-side {
        width: 40%;
    }
}

@media screen and (max-width: 900px) {
    .login .right-side {
        width: 100%;
    }

    .login .left-side {
        display: none;
    }
}
