.sidebar-item-dropdown {
    width: 100%;
    position: relative;
}

.dropdown-title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-item-dropdown .dropdown-title {
    display: flex;
    align-items: center;
}

.sidebar-item-dropdown .dropdown-item-toggle {
    color: var(--font-color-3);
}

.sidebar-item-dropdown .dropdown-list {
    margin-top: 10px;
    background-color: inherit;
    padding: 0;
    border-top: 1px solid var(--shadow-color-2);
}

/* .sidebar-item-dropdown .dropdown-list {
    position: absolute;
    top: 25px;
    right:40px;
    box-shadow: 0 0 5px 1px var(--shadow-color);
    background-color: var(--bg-color-1);
    border-radius: 5px;
    z-index: 120;
} */

.sidebar-item-dropdown .dropdown-item {
    list-style: none;
    font-size: 15px;
    padding: 7px 30px 7px 0;
    transition: all .1s;
    color: var(--font-color-1);
}

.sidebar-item-dropdown .dropdown-item:hover,
.sidebar-item-dropdown .dropdown-item.active{
    color: var(--color-1);
}

.dropdown-item-toggle.hidden {
    display: none;
}
