.pannel {
    display: flex;
    position: relative;
}

.body-section {
    flex: 5;
}

.content-section {
    background-color: var(--bg-color-2);
    height: 100vh;
    position: relative;
    overflow: auto;
}


/* Other Pages */
.info-list {
    margin: 40px 30px;
    background-color: var(--bg-color-1);
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px var(--shadow-color);
}


/* Media Query */
@media screen and (max-width: 500px) {
    .info-list {
        margin: 20px 10px;
        padding: 20px 15px;
    }
}